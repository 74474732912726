import { default as _91_46_46_46token_93mSwflofR7rMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/authorization-social/[...token].vue?macro=true";
import { default as index96lRmwvEyzMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/authorization/index.vue?macro=true";
import { default as indexY34ycdyFarMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/api/index.vue?macro=true";
import { default as indexjdMbu5mml2Meta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/index.vue?macro=true";
import { default as indexi9se4QpOtLMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/numbers-history/index.vue?macro=true";
import { default as indexQ1N6CntqOcMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/payments/index.vue?macro=true";
import { default as indexjZ2jGMVX0LMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/profile/index.vue?macro=true";
import { default as indexMJP5XaX4WMMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/forgot-password/index.vue?macro=true";
import { default as index7sCWDIeImCMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/index.vue?macro=true";
import { default as indexNr491nSbtDMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/payment/result/index.vue?macro=true";
import { default as index550nbQJSFYMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/registration/index.vue?macro=true";
import { default as _91_46_46_46token_93tgGrILNFOBMeta } from "/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/reset-password/[...token].vue?macro=true";
export default [
  {
    name: _91_46_46_46token_93mSwflofR7rMeta?.name ?? "authorization-social-token___en___default",
    path: _91_46_46_46token_93mSwflofR7rMeta?.path ?? "/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93mSwflofR7rMeta || {},
    alias: _91_46_46_46token_93mSwflofR7rMeta?.alias || [],
    redirect: _91_46_46_46token_93mSwflofR7rMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93mSwflofR7rMeta?.name ?? "authorization-social-token___en",
    path: _91_46_46_46token_93mSwflofR7rMeta?.path ?? "/en/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93mSwflofR7rMeta || {},
    alias: _91_46_46_46token_93mSwflofR7rMeta?.alias || [],
    redirect: _91_46_46_46token_93mSwflofR7rMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93mSwflofR7rMeta?.name ?? "authorization-social-token___ru",
    path: _91_46_46_46token_93mSwflofR7rMeta?.path ?? "/ru/authorization-social/:token(.*)*",
    meta: _91_46_46_46token_93mSwflofR7rMeta || {},
    alias: _91_46_46_46token_93mSwflofR7rMeta?.alias || [],
    redirect: _91_46_46_46token_93mSwflofR7rMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/authorization-social/[...token].vue").then(m => m.default || m)
  },
  {
    name: index96lRmwvEyzMeta?.name ?? "authorization___en___default",
    path: index96lRmwvEyzMeta?.path ?? "/authorization",
    meta: index96lRmwvEyzMeta || {},
    alias: index96lRmwvEyzMeta?.alias || [],
    redirect: index96lRmwvEyzMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: index96lRmwvEyzMeta?.name ?? "authorization___en",
    path: index96lRmwvEyzMeta?.path ?? "/en/authorization",
    meta: index96lRmwvEyzMeta || {},
    alias: index96lRmwvEyzMeta?.alias || [],
    redirect: index96lRmwvEyzMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: index96lRmwvEyzMeta?.name ?? "authorization___ru",
    path: index96lRmwvEyzMeta?.path ?? "/ru/authorization",
    meta: index96lRmwvEyzMeta || {},
    alias: index96lRmwvEyzMeta?.alias || [],
    redirect: index96lRmwvEyzMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/authorization/index.vue").then(m => m.default || m)
  },
  {
    name: indexY34ycdyFarMeta?.name ?? "cabinet-api___en___default",
    path: indexY34ycdyFarMeta?.path ?? "/cabinet/api",
    meta: indexY34ycdyFarMeta || {},
    alias: indexY34ycdyFarMeta?.alias || [],
    redirect: indexY34ycdyFarMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexY34ycdyFarMeta?.name ?? "cabinet-api___en",
    path: indexY34ycdyFarMeta?.path ?? "/en/cabinet/api",
    meta: indexY34ycdyFarMeta || {},
    alias: indexY34ycdyFarMeta?.alias || [],
    redirect: indexY34ycdyFarMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexY34ycdyFarMeta?.name ?? "cabinet-api___ru",
    path: indexY34ycdyFarMeta?.path ?? "/ru/cabinet/api",
    meta: indexY34ycdyFarMeta || {},
    alias: indexY34ycdyFarMeta?.alias || [],
    redirect: indexY34ycdyFarMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/api/index.vue").then(m => m.default || m)
  },
  {
    name: indexjdMbu5mml2Meta?.name ?? "cabinet___en___default",
    path: indexjdMbu5mml2Meta?.path ?? "/cabinet",
    meta: indexjdMbu5mml2Meta || {},
    alias: indexjdMbu5mml2Meta?.alias || [],
    redirect: indexjdMbu5mml2Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexjdMbu5mml2Meta?.name ?? "cabinet___en",
    path: indexjdMbu5mml2Meta?.path ?? "/en/cabinet",
    meta: indexjdMbu5mml2Meta || {},
    alias: indexjdMbu5mml2Meta?.alias || [],
    redirect: indexjdMbu5mml2Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexjdMbu5mml2Meta?.name ?? "cabinet___ru",
    path: indexjdMbu5mml2Meta?.path ?? "/ru/cabinet",
    meta: indexjdMbu5mml2Meta || {},
    alias: indexjdMbu5mml2Meta?.alias || [],
    redirect: indexjdMbu5mml2Meta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/index.vue").then(m => m.default || m)
  },
  {
    name: indexi9se4QpOtLMeta?.name ?? "cabinet-numbers-history___en___default",
    path: indexi9se4QpOtLMeta?.path ?? "/cabinet/numbers-history",
    meta: indexi9se4QpOtLMeta || {},
    alias: indexi9se4QpOtLMeta?.alias || [],
    redirect: indexi9se4QpOtLMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexi9se4QpOtLMeta?.name ?? "cabinet-numbers-history___en",
    path: indexi9se4QpOtLMeta?.path ?? "/en/cabinet/numbers-history",
    meta: indexi9se4QpOtLMeta || {},
    alias: indexi9se4QpOtLMeta?.alias || [],
    redirect: indexi9se4QpOtLMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexi9se4QpOtLMeta?.name ?? "cabinet-numbers-history___ru",
    path: indexi9se4QpOtLMeta?.path ?? "/ru/cabinet/numbers-history",
    meta: indexi9se4QpOtLMeta || {},
    alias: indexi9se4QpOtLMeta?.alias || [],
    redirect: indexi9se4QpOtLMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/numbers-history/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1N6CntqOcMeta?.name ?? "cabinet-payments___en___default",
    path: indexQ1N6CntqOcMeta?.path ?? "/cabinet/payments",
    meta: indexQ1N6CntqOcMeta || {},
    alias: indexQ1N6CntqOcMeta?.alias || [],
    redirect: indexQ1N6CntqOcMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1N6CntqOcMeta?.name ?? "cabinet-payments___en",
    path: indexQ1N6CntqOcMeta?.path ?? "/en/cabinet/payments",
    meta: indexQ1N6CntqOcMeta || {},
    alias: indexQ1N6CntqOcMeta?.alias || [],
    redirect: indexQ1N6CntqOcMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexQ1N6CntqOcMeta?.name ?? "cabinet-payments___ru",
    path: indexQ1N6CntqOcMeta?.path ?? "/ru/cabinet/payments",
    meta: indexQ1N6CntqOcMeta || {},
    alias: indexQ1N6CntqOcMeta?.alias || [],
    redirect: indexQ1N6CntqOcMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/payments/index.vue").then(m => m.default || m)
  },
  {
    name: indexjZ2jGMVX0LMeta?.name ?? "cabinet-profile___en___default",
    path: indexjZ2jGMVX0LMeta?.path ?? "/cabinet/profile",
    meta: indexjZ2jGMVX0LMeta || {},
    alias: indexjZ2jGMVX0LMeta?.alias || [],
    redirect: indexjZ2jGMVX0LMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexjZ2jGMVX0LMeta?.name ?? "cabinet-profile___en",
    path: indexjZ2jGMVX0LMeta?.path ?? "/en/cabinet/profile",
    meta: indexjZ2jGMVX0LMeta || {},
    alias: indexjZ2jGMVX0LMeta?.alias || [],
    redirect: indexjZ2jGMVX0LMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexjZ2jGMVX0LMeta?.name ?? "cabinet-profile___ru",
    path: indexjZ2jGMVX0LMeta?.path ?? "/ru/cabinet/profile",
    meta: indexjZ2jGMVX0LMeta || {},
    alias: indexjZ2jGMVX0LMeta?.alias || [],
    redirect: indexjZ2jGMVX0LMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/cabinet/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexMJP5XaX4WMMeta?.name ?? "forgot-password___en___default",
    path: indexMJP5XaX4WMMeta?.path ?? "/forgot-password",
    meta: indexMJP5XaX4WMMeta || {},
    alias: indexMJP5XaX4WMMeta?.alias || [],
    redirect: indexMJP5XaX4WMMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexMJP5XaX4WMMeta?.name ?? "forgot-password___en",
    path: indexMJP5XaX4WMMeta?.path ?? "/en/forgot-password",
    meta: indexMJP5XaX4WMMeta || {},
    alias: indexMJP5XaX4WMMeta?.alias || [],
    redirect: indexMJP5XaX4WMMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: indexMJP5XaX4WMMeta?.name ?? "forgot-password___ru",
    path: indexMJP5XaX4WMMeta?.path ?? "/ru/forgot-password",
    meta: indexMJP5XaX4WMMeta || {},
    alias: indexMJP5XaX4WMMeta?.alias || [],
    redirect: indexMJP5XaX4WMMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/forgot-password/index.vue").then(m => m.default || m)
  },
  {
    name: index7sCWDIeImCMeta?.name ?? "index___en___default",
    path: index7sCWDIeImCMeta?.path ?? "/",
    meta: index7sCWDIeImCMeta || {},
    alias: index7sCWDIeImCMeta?.alias || [],
    redirect: index7sCWDIeImCMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7sCWDIeImCMeta?.name ?? "index___en",
    path: index7sCWDIeImCMeta?.path ?? "/en",
    meta: index7sCWDIeImCMeta || {},
    alias: index7sCWDIeImCMeta?.alias || [],
    redirect: index7sCWDIeImCMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index7sCWDIeImCMeta?.name ?? "index___ru",
    path: index7sCWDIeImCMeta?.path ?? "/ru",
    meta: index7sCWDIeImCMeta || {},
    alias: index7sCWDIeImCMeta?.alias || [],
    redirect: index7sCWDIeImCMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexNr491nSbtDMeta?.name ?? "payment-result___en___default",
    path: indexNr491nSbtDMeta?.path ?? "/payment/result",
    meta: indexNr491nSbtDMeta || {},
    alias: indexNr491nSbtDMeta?.alias || [],
    redirect: indexNr491nSbtDMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexNr491nSbtDMeta?.name ?? "payment-result___en",
    path: indexNr491nSbtDMeta?.path ?? "/en/payment/result",
    meta: indexNr491nSbtDMeta || {},
    alias: indexNr491nSbtDMeta?.alias || [],
    redirect: indexNr491nSbtDMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: indexNr491nSbtDMeta?.name ?? "payment-result___ru",
    path: indexNr491nSbtDMeta?.path ?? "/ru/payment/result",
    meta: indexNr491nSbtDMeta || {},
    alias: indexNr491nSbtDMeta?.alias || [],
    redirect: indexNr491nSbtDMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/payment/result/index.vue").then(m => m.default || m)
  },
  {
    name: index550nbQJSFYMeta?.name ?? "registration___en___default",
    path: index550nbQJSFYMeta?.path ?? "/registration",
    meta: index550nbQJSFYMeta || {},
    alias: index550nbQJSFYMeta?.alias || [],
    redirect: index550nbQJSFYMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: index550nbQJSFYMeta?.name ?? "registration___en",
    path: index550nbQJSFYMeta?.path ?? "/en/registration",
    meta: index550nbQJSFYMeta || {},
    alias: index550nbQJSFYMeta?.alias || [],
    redirect: index550nbQJSFYMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: index550nbQJSFYMeta?.name ?? "registration___ru",
    path: index550nbQJSFYMeta?.path ?? "/ru/registration",
    meta: index550nbQJSFYMeta || {},
    alias: index550nbQJSFYMeta?.alias || [],
    redirect: index550nbQJSFYMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/registration/index.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93tgGrILNFOBMeta?.name ?? "reset-password-token___en___default",
    path: _91_46_46_46token_93tgGrILNFOBMeta?.path ?? "/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93tgGrILNFOBMeta || {},
    alias: _91_46_46_46token_93tgGrILNFOBMeta?.alias || [],
    redirect: _91_46_46_46token_93tgGrILNFOBMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93tgGrILNFOBMeta?.name ?? "reset-password-token___en",
    path: _91_46_46_46token_93tgGrILNFOBMeta?.path ?? "/en/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93tgGrILNFOBMeta || {},
    alias: _91_46_46_46token_93tgGrILNFOBMeta?.alias || [],
    redirect: _91_46_46_46token_93tgGrILNFOBMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/reset-password/[...token].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46token_93tgGrILNFOBMeta?.name ?? "reset-password-token___ru",
    path: _91_46_46_46token_93tgGrILNFOBMeta?.path ?? "/ru/reset-password/:token(.*)*",
    meta: _91_46_46_46token_93tgGrILNFOBMeta || {},
    alias: _91_46_46_46token_93tgGrILNFOBMeta?.alias || [],
    redirect: _91_46_46_46token_93tgGrILNFOBMeta?.redirect,
    component: () => import("/var/www/smscode.dev.sitisit.ru/frontend/smscode-front-20241009124930/pages/reset-password/[...token].vue").then(m => m.default || m)
  }
]